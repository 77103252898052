<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Login</h1>
      <form @submit.prevent="login">
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input v-model="email" class="input" type="email" placeholder="Email">
          </div>
        </div>

        <div class="field">
          <label class="label">Password</label>
          <div class="control">
            <input v-model="password" class="input" type="password" placeholder="Password">
          </div>
        </div>

        <div class="control">
          <button class="button is-primary">Login</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    ...mapActions(['login']),
    async login() {
      try {
        await this.login({ email: this.email, password: this.password });
        this.$router.push({ name: 'Dashboard' });
      } catch (error) {
        alert('Login failed. Please check your credentials.');
      }
    },
  },
};
</script>

<style scoped>
/* Add some styles for the login form */
</style>
