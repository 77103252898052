<template>
  <div id="app">
    <NavBar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import Footer from './components/FooterComponent.vue';

export default {
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
}
</style>
