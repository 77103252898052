<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Order Management System</strong> by <a href="https://example.com">Your Name</a>.
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>
