// src/views/Dashboard.vue
<template>
  <div>
    <h1>Home view</h1>
    <button @click="logout">Logout</button>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  methods: {
    async logout() {
      try {
        await api.post('/logout');
      } catch (error) {
        console.error(error);
      } finally {
        localStorage.removeItem('auth_token');
        this.$router.push('/login');
      }
    },
  },
};
</script>