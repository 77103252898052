import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
    state: {
        user: null,
        token: localStorage.getItem('token') || '',
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        LOGOUT(state) {
            state.user = null;
            state.token = '';
            localStorage.removeItem('token');
        },
    },
    actions: {
        async login({ commit }, credentials) {
            const response = await axios.post('http://localhost/api/login', credentials);
            commit('SET_TOKEN', response.data.token);
            commit('SET_USER', response.data.user);
            return response;
        },
        logout({ commit }) {
            commit('LOGOUT');
        },
        async fetchUser({ commit }) {
            try {
                const response = await axios.get('http://localhost/api/user', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                commit('SET_USER', response.data);
            } catch (error) {
                commit('LOGOUT');
            }
        },
    },
});

export default store;