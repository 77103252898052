import axios from 'axios';
import router from '@/router';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // Update with your backend URL
    withCredentials: true,
});

api.interceptors.request.use(config => {
    const token = localStorage.getItem('auth_token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            localStorage.removeItem('auth_token');
            router.push('/login');
        }
        return Promise.reject(error);
    }
);

export default api;