<template>
  <div v-if="isLoggedIn">
    <h1>Dashboard</h1>
    <button @click="logout">Logout</button>
  </div>
  <div v-else>
    <p>You are not logged in. Please <router-link to="/login">login</router-link>.</p>
  </div>
</template>

<script>
import { isAuthenticated } from '@/utils/auth';
import api from '@/services/api';

export default {
  name: 'UserDashboard',
  computed: {
    isLoggedIn() {
      return isAuthenticated();
    }
  },
  methods: {
    logout() {
      api.post('/logout')
          .then(() => {
            localStorage.removeItem('auth_token');
            this.$router.push('/login');
          })
          .catch(error => {
            console.error(error);
            localStorage.removeItem('auth_token');
            this.$router.push('/login');
          });
    }
  }
};
</script>